import './App.scss';
import MoiComponent from './components/MoiComponent/MoiComponent';

function App() {
  return (
    <div className='App'>
      <div className='hug'>
        <MoiComponent />
      </div>
    </div>
  );
}

export default App;
