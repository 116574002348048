import React, { useState } from 'react';
import { IoLogoLinkedin } from 'react-icons/io5';

const MoiComponent = () => {
  const [loading, setLoading] = useState(true);

  document.onreadystatechange = () => {
    if (document.readyState === 'complete') {
      setLoading(false);
    }
  };

  return (
    <div
      className={`${
        !loading ? 'show' : ''
      }  moi bg-white shadow-xl border-0  text-gray-800 rounded bg-yellow-300`}
    >
      <div className='text-block'>
        <div>
          <h1 className='font-bold text-3xl md:text-4xl mb-0 pb-5'>
            Kom ihåg PR*
          </h1>

          <p className='mb-4 text-base md:text-lg'>
            Jonas Paulsrud is a communications consultant who focuses on
            companies and organizations within IT, information security,
            industry, green tech and communications. Please feel free to connect
            with me on
            <a
              href='https://www.linkedin.com/in/jonas-paulsrud-a4ab2/'
              className='ml-1  inline-block text-base md:text-lg md:leading-none leading-none border-b border-gray-900 '
            >
              LinkedIn. <i className='mdi mdi-arrow-right'></i>
            </a>
          </p>
          <small className='mt-4'>*Swedish for "Remember PR"</small>
        </div>
        <div className='address-and-linkedin text-sm'>
          <div>
            <p>
              <small className='font-bold'>Minjon AB</small>
            </p>
            <p>
              <small>Kungsporten 3A SE-427 50 BILLDAL</small>
            </p>
            <p>
              <small>
                <a
                  className='eading-none border-b border-gray-900'
                  href='tel:+46708133472'
                >
                  +46 (0)708-13 34 72
                </a>
              </small>
            </p>
            <p>
              <small>
                <a
                  className='eading-none border-b border-gray-900'
                  href='mailto:jonas@paulsrud.com'
                >
                  jonas@paulsrud.com
                </a>
              </small>
            </p>
          </div>

          <a href='https://www.linkedin.com/in/jonas-paulsrud-a4ab2/'>
            <IoLogoLinkedin />
          </a>
        </div>
      </div>
      <div className='image-wrapper'>
        <div className='rounded-r image-block'></div>
      </div>
    </div>
  );
};

export default MoiComponent;
